const Lock = () => {
    return (
        <>
<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
  <g filter="url(#filter0_dd_10795_383207)">
    <path d="M28.3333 18.3333V13.3333C28.3333 8.73096 24.6024 5 20 5C15.3976 5 11.6667 8.73096 11.6667 13.3333V18.3333M13 35H27C29.8003 35 31.2004 35 32.27 34.455C33.2108 33.9757 33.9757 33.2108 34.455 32.27C35 31.2004 35 29.8003 35 27V26.3333C35 23.5331 35 22.1329 34.455 21.0634C33.9757 20.1226 33.2108 19.3577 32.27 18.8783C31.2004 18.3333 29.8003 18.3333 27 18.3333H13C10.1997 18.3333 8.79961 18.3333 7.73005 18.8783C6.78924 19.3577 6.02433 20.1226 5.54497 21.0634C5 22.1329 5 23.5331 5 26.3333V27C5 29.8003 5 31.2004 5.54497 32.27C6.02433 33.2108 6.78924 33.9757 7.73005 34.455C8.79961 35 10.1997 35 13 35Z" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round"/>
  </g>
  <defs>
    <filter id="filter0_dd_10795_383207" x="-3" y="-2" width="46" height="46" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="1"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10795_383207"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="1.5"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"/>
      <feBlend mode="normal" in2="effect1_dropShadow_10795_383207" result="effect2_dropShadow_10795_383207"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_10795_383207" result="shape"/>
    </filter>
  </defs>
</svg>
        </>
    );
};

export default Lock;

